/*
 * Copyright 2019 Atlas Copco. All rights reserved.
 */

/**
 * Status mapping to back-end so add/modify status constants here if status constants in back-end are changed
 */
const STATUS_DISPLAY_NAMES = {
  DELIVERED: 'Delivered',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  SHIPPED: 'Shipped',
  PARTIALLY_SHIPPED: 'Partially Shipped',
  CONFIRMED: 'Confirmed',
  PARTIALLY_CONFIRMED: 'Partially Confirmed',
  RECEIVED: 'Acknowledged',
  CANCELLED: 'Cancelled',
  UNDER_INVESTIGATION: 'Under Investigation',
  CONTACT_US: 'Contact Us'
};

const StatusConstants = {
  STATUS_DISPLAY_NAMES,
};

export default StatusConstants;
